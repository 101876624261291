import { combineReducers } from 'redux';
import { TOGGLE_COOKIEBAR, TOGGLE_COOKIE_GA, TOGGLE_INSTALLATION_GUIDE } from 'actions/index';

const initialState = {
  isCookieBarOpen: null,
  isCookieGaSet: null,
  isShowInstallationGuide: false,
}

const root = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_INSTALLATION_GUIDE:
      return {
        ...state,
        isShowInstallationGuide: payload,
      }
    case TOGGLE_COOKIEBAR:
      return {
        ...state,
        isCookieBarOpen: payload,
      }
    case TOGGLE_COOKIE_GA:
      return {
        ...state,
        isCookieGaSet: payload,
      }
    default:
      return state
  }
}

export default combineReducers({ root })
