export const TOGGLE_COOKIEBAR = 'TOGGLE_COOKIEBAR';
export const TOGGLE_COOKIE_GA = 'TOGGLE_COOKIE_GA';
export const TOGGLE_INSTALLATION_GUIDE = 'TOGGLE_INSTALLATION_GUIDE';

export const toggleCookieBar = isCookieBarOpen => ({
  type: TOGGLE_COOKIEBAR, payload: isCookieBarOpen
});

export const toggleCookieGa = isGaSet => ({
  type: TOGGLE_COOKIE_GA, payload: isGaSet
});

export const toggleInstallationGuideAction = isShow => ({
  type: TOGGLE_INSTALLATION_GUIDE, payload: isShow
});
