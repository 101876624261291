exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-andrii-garanin-index-js": () => import("./../../../src/pages/andrii_garanin/index.js" /* webpackChunkName: "component---src-pages-andrii-garanin-index-js" */),
  "component---src-pages-andrii-garanin-person-js": () => import("./../../../src/pages/andrii_garanin/Person.js" /* webpackChunkName: "component---src-pages-andrii-garanin-person-js" */),
  "component---src-pages-andriy-index-js": () => import("./../../../src/pages/andriy/index.js" /* webpackChunkName: "component---src-pages-andriy-index-js" */),
  "component---src-pages-andriy-person-js": () => import("./../../../src/pages/andriy/Person.js" /* webpackChunkName: "component---src-pages-andriy-person-js" */),
  "component---src-pages-artem-index-js": () => import("./../../../src/pages/artem/index.js" /* webpackChunkName: "component---src-pages-artem-index-js" */),
  "component---src-pages-artem-person-js": () => import("./../../../src/pages/artem/Person.js" /* webpackChunkName: "component---src-pages-artem-person-js" */),
  "component---src-pages-asic-asic-js": () => import("./../../../src/pages/asic/Asic.js" /* webpackChunkName: "component---src-pages-asic-asic-js" */),
  "component---src-pages-asic-index-js": () => import("./../../../src/pages/asic/index.js" /* webpackChunkName: "component---src-pages-asic-index-js" */),
  "component---src-pages-asichub-asic-hub-js": () => import("./../../../src/pages/asichub/AsicHub.js" /* webpackChunkName: "component---src-pages-asichub-asic-hub-js" */),
  "component---src-pages-asichub-index-js": () => import("./../../../src/pages/asichub/index.js" /* webpackChunkName: "component---src-pages-asichub-index-js" */),
  "component---src-pages-asicvvs-asicvvs-js": () => import("./../../../src/pages/asicvvs/Asicvvs.js" /* webpackChunkName: "component---src-pages-asicvvs-asicvvs-js" */),
  "component---src-pages-asicvvs-index-js": () => import("./../../../src/pages/asicvvs/index.js" /* webpackChunkName: "component---src-pages-asicvvs-index-js" */),
  "component---src-pages-changelog-changelog-js": () => import("./../../../src/pages/changelog/changelog.js" /* webpackChunkName: "component---src-pages-changelog-changelog-js" */),
  "component---src-pages-changelog-index-js": () => import("./../../../src/pages/changelog/index.js" /* webpackChunkName: "component---src-pages-changelog-index-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/contact/contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-energy-energy-page-js": () => import("./../../../src/pages/energy/EnergyPage.js" /* webpackChunkName: "component---src-pages-energy-energy-page-js" */),
  "component---src-pages-energy-index-js": () => import("./../../../src/pages/energy/index.js" /* webpackChunkName: "component---src-pages-energy-index-js" */),
  "component---src-pages-enterprise-enterprise-js": () => import("./../../../src/pages/enterprise/Enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-enterprise-js" */),
  "component---src-pages-enterprise-index-js": () => import("./../../../src/pages/enterprise/index.js" /* webpackChunkName: "component---src-pages-enterprise-index-js" */),
  "component---src-pages-features-features-js": () => import("./../../../src/pages/features/features.js" /* webpackChunkName: "component---src-pages-features-features-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-index-js": () => import("./../../../src/pages/install/index.js" /* webpackChunkName: "component---src-pages-install-index-js" */),
  "component---src-pages-install-install-js": () => import("./../../../src/pages/install/install.js" /* webpackChunkName: "component---src-pages-install-install-js" */),
  "component---src-pages-kaspa-contest-index-js": () => import("./../../../src/pages/kaspa-contest/index.js" /* webpackChunkName: "component---src-pages-kaspa-contest-index-js" */),
  "component---src-pages-kaspa-contest-kaspa-contest-page-js": () => import("./../../../src/pages/kaspa-contest/KaspaContestPage.js" /* webpackChunkName: "component---src-pages-kaspa-contest-kaspa-contest-page-js" */),
  "component---src-pages-keaton-index-js": () => import("./../../../src/pages/keaton/index.js" /* webpackChunkName: "component---src-pages-keaton-index-js" */),
  "component---src-pages-keaton-person-js": () => import("./../../../src/pages/keaton/Person.js" /* webpackChunkName: "component---src-pages-keaton-person-js" */),
  "component---src-pages-lena-index-js": () => import("./../../../src/pages/lena/index.js" /* webpackChunkName: "component---src-pages-lena-index-js" */),
  "component---src-pages-lena-person-js": () => import("./../../../src/pages/lena/Person.js" /* webpackChunkName: "component---src-pages-lena-person-js" */),
  "component---src-pages-os-index-js": () => import("./../../../src/pages/os/index.js" /* webpackChunkName: "component---src-pages-os-index-js" */),
  "component---src-pages-os-os-js": () => import("./../../../src/pages/os/os.js" /* webpackChunkName: "component---src-pages-os-os-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-pricing-js": () => import("./../../../src/pages/pricing/pricing.js" /* webpackChunkName: "component---src-pages-pricing-pricing-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-privacy-privacy-js": () => import("./../../../src/pages/privacy/Privacy.js" /* webpackChunkName: "component---src-pages-privacy-privacy-js" */),
  "component---src-pages-referral-index-js": () => import("./../../../src/pages/referral/index.js" /* webpackChunkName: "component---src-pages-referral-index-js" */),
  "component---src-pages-referral-referral-js": () => import("./../../../src/pages/referral/Referral.js" /* webpackChunkName: "component---src-pages-referral-referral-js" */),
  "component---src-pages-statistics-index-js": () => import("./../../../src/pages/statistics/index.js" /* webpackChunkName: "component---src-pages-statistics-index-js" */),
  "component---src-pages-statistics-statistics-js": () => import("./../../../src/pages/statistics/Statistics.js" /* webpackChunkName: "component---src-pages-statistics-statistics-js" */),
  "component---template-article-mdx-article-mdx-js": () => import("./../../../template/ArticleMdx/ArticleMdx.js" /* webpackChunkName: "component---template-article-mdx-article-mdx-js" */),
  "component---template-articles-root-articles-root-js": () => import("./../../../template/ArticlesRoot/ArticlesRoot.js" /* webpackChunkName: "component---template-articles-root-articles-root-js" */),
  "component---template-knowledge-base-category-knowledge-base-category-js": () => import("./../../../template/KnowledgeBaseCategory/KnowledgeBaseCategory.js" /* webpackChunkName: "component---template-knowledge-base-category-knowledge-base-category-js" */),
  "component---template-knowledge-base-item-knowledge-base-item-js": () => import("./../../../template/KnowledgeBaseItem/KnowledgeBaseItem.js" /* webpackChunkName: "component---template-knowledge-base-item-knowledge-base-item-js" */),
  "component---template-knowledge-base-root-knowledge-base-root-js": () => import("./../../../template/KnowledgeBaseRoot/KnowledgeBaseRoot.js" /* webpackChunkName: "component---template-knowledge-base-root-knowledge-base-root-js" */),
  "component---template-page-page-js": () => import("./../../../template/Page/Page.js" /* webpackChunkName: "component---template-page-page-js" */)
}

