import React from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from 'reducers/index';

const store = createStore(rootReducer, composeWithDevTools())

export function ReduxWrapper(props) {
  return <Provider store={store}>{props.children}</Provider>;
}
